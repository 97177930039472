import React, { useState } from "react";

import {
  View,
  Text,
  StyleSheet,
  Dimensions,
  TextInput,
  Button,
  Alert,
  TouchableOpacity,
} from "react-native";
import { firebase } from "../configs/firebaseConfig";
import { createStackNavigator } from "react-navigation-stack";
import Constants from "expo-constants";

import HeaderView from "../components/HeaderView";
import FooterView from "../components/FooterView";

import "../assets/css/main.css";
import "../assets/css/noscript.css";

export default function ContactScreen({ navigation }) {
  return (
    <>
      <html>
        <head>
          <title>DarkLand by GenieDevs</title>
          <link rel="stylesheet" href="./assets/css/main.css" />
          <noscript>
            <link rel="stylesheet" href="./assets/css/noscript.css" />
          </noscript>
        </head>
        <body class="is-preload landing">
          <View style={styles.container}>
            <div id="page-wrapper">
              <HeaderView navigation={navigation} />
              <div id="main" class="wrapper style1">
                <div class="container">
                  <header class="major">
                    <h2>Contact Us</h2>
                    <p>
                      Get in touch with us to learn more about our services.
                    </p>
                  </header>
                  <div class="row gtr-150">
                    <div class="col-12 col-12-medium">
                      <section id="content">
                        <div class="container">
                          <form method="post" action="#">
                            <div class="row gtr-uniform gtr-50">
                              <div class="col-6 col-12-xsmall">
                                <input
                                  type="text"
                                  name="name"
                                  id="name"
                                  value=""
                                  placeholder="Name"
                                />
                              </div>
                              <div class="col-6 col-12-xsmall">
                                <input
                                  type="email"
                                  name="email"
                                  id="email"
                                  value=""
                                  placeholder="Email"
                                />
                              </div>
                              <div class="col-12">
                                <select name="category" id="category">
                                  <option value="">Select a purpose</option>
                                  <option value="1">General Inquiry</option>
                                  <option value="2">Technical Support</option>
                                  <option value="3">Sales Inquiry</option>
                                  <option value="4">Partnership Inquiry</option>
                                  <option value="5">Feedback</option>
                                  <option value="6">Job Inquiry</option>
                                  <option value="7">Media Inquiry</option>
                                  <option value="8">Complaint</option>
                                  <option value="9">Suggestion</option>
                                </select>
                              </div>
                              <div class="col-12">
                                <textarea
                                  name="message"
                                  id="message"
                                  placeholder="Enter your message"
                                  rows="6"
                                ></textarea>
                              </div>
                              {/* <div class="col-6 col-12-medium">
                        <input type="checkbox" id="copy" name="copy" />
                        <label for="copy">
                          Email me a copy of this message
                        </label></div> */}
                              <div class="col-12">
                                <ul class="actions">
                                  <li>
                                    <input
                                      type="submit"
                                      value="Send Message"
                                      class="primary"
                                    />
                                  </li>
                                  <li>
                                    <input type="reset" value="Reset" />
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </form>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
              <FooterView navigation={navigation} />
            </div>

            <script src="https://baby-monitor-b1e03.web.app/js/jquery.min.js"></script>
            <script src="https://baby-monitor-b1e03.web.app/js/jquery.scrolly.min.js"></script>
            <script src="https://baby-monitor-b1e03.web.app/js/jquery.dropotron.min.js"></script>
            <script src="https://baby-monitor-b1e03.web.app/js/jquery.scrollex.min.js"></script>
            <script src="https://baby-monitor-b1e03.web.app/js/browser.min.js"></script>
            <script src="https://baby-monitor-b1e03.web.app/js/breakpoints.min.js"></script>
            <script src="https://baby-monitor-b1e03.web.app/js/util.js"></script>
            <script src="https://baby-monitor-b1e03.web.app/js/main.js"></script>
          </View>
        </body>
      </html>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    display: "block",
  },
});
