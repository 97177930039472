import React, { useState } from "react";

import {
  View,
  Text,
  StyleSheet,
  Dimensions,
  TextInput,
  Button,
  Alert,
  TouchableOpacity,
} from "react-native";
import HeaderView from "../components/HeaderView";
import FooterView from "../components/FooterView";

import "../assets/css/main.css";
import "../assets/css/noscript.css";

export default function EmptyScreen({ navigation }) {
  return (
    <>
      <html>
        <head>
          <title>DarkLand by GenieDevs</title>
        </head>
        <body class="landing">
          <View style={styles.container}>
            <div id="page-wrapper">
              <HeaderView navigation={navigation} />
              <div id="main" class="wrapper style1">
                <div class="container">
                  <header class="major">
                    <h2>401</h2>
                    <p>
                      Ipsum dolor feugiat aliquam tempus sed magna lorem
                      consequat accumsan
                    </p>
                  </header>
                  <div class="row gtr-150">
                    <div class="col-8 col-12-medium">
                      <section id="content">
                        <a href="#" class="image fit">
                          <img src="images/pic06.jpg" alt="" />
                        </a>
                        <h3>Dolore Amet Consequat</h3>
                        <p>
                          Aliquam massa urna, imperdiet sit amet mi non,
                          bibendum euismod est. Curabitur mi justo, tincidunt
                          vel eros ullamcorper, porta cursus justo. Cras vel
                          neque eros. Vestibulum diam quam, mollis at magna
                          consectetur non, malesuada quis augue. Morbi tincidunt
                          pretium interdum est. Curabitur mi justo, tincidunt
                          vel eros ullamcorper, porta cursus justo. Cras vel
                          neque eros. Vestibulum diam.
                        </p>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
              <FooterView navigation={navigation} />
            </div>

            <script src="https://baby-monitor-b1e03.web.app/js/jquery.min.js"></script>
            <script src="https://baby-monitor-b1e03.web.app/js/jquery.scrolly.min.js"></script>
            <script src="https://baby-monitor-b1e03.web.app/js/jquery.dropotron.min.js"></script>
            <script src="https://baby-monitor-b1e03.web.app/js/jquery.scrollex.min.js"></script>
            <script src="https://baby-monitor-b1e03.web.app/js/browser.min.js"></script>
            <script src="https://baby-monitor-b1e03.web.app/js/breakpoints.min.js"></script>
            <script src="https://baby-monitor-b1e03.web.app/js/util.js"></script>
            <script src="https://baby-monitor-b1e03.web.app/js/main.js"></script>
          </View>
        </body>
      </html>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    display: "block",
  },
});
