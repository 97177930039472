import React, { useState, useEffect } from "react";
import { Image, Alert, TouchableOpacity } from "react-native";

const HeaderView = ({ navigation }) => {
  return (
    <header id="header">
      <h1 id="logo">
        <a href="#/home">
          <Image
            style={{ width: 133, height: 44, margin: 12 }}
            source={require("../screens/images/logo.png")}
          />
        </a>
      </h1>
      <nav id="nav">
        <ul>
          <li>
            <a href="#/about">About</a>
          </li>
          <li>
            <a href="#/services">Services</a>
          </li>
          <li>
            <a href="#/career">Career</a>
          </li>
          {/* <li>
            <a href="#/products">Products</a>
          </li> */}
          <li>
            <a href="#/contact">Contact</a>
          </li>
          <li>
            <a href="#/quote" class="button primary">
              Quote
            </a>
          </li>
        </ul>
      </nav>
    </header>
  
  );
};

export default HeaderView;
