import React, { useState } from "react";

import {
  View,
  Text,
  StyleSheet,
  Image,
  TextInput,
  Button,
  Alert,
  TouchableOpacity,
} from "react-native";
import { firebase } from "../configs/firebaseConfig";
import { createStackNavigator } from "react-navigation-stack";
import Constants from "expo-constants";

import HeaderView from "../components/HeaderView";
import FooterView from "../components/FooterView";

import "../assets/css/main.css";
import "../assets/css/noscript.css";

export default function MainScreen({ navigation }) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleNameChange = (value) => setName(value);
  const handleEmailChange = (value) => setEmail(value);
  const handleMessageChange = (value) => setMessage(value);

  const handleSubmit = () => {
    firebase
      .firestore()
      .collection("forms")
      .add({
        name,
        email,
        message,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      })
      .then(() => {
        Alert.alert("Save", "Contact added successfully!");
        console.log("Contact added successfully!");
        setName("");
        setEmail("");
        setMessage("");
      })
      .catch((error) => {
        console.error("Error adding contact: ", error);
      });
  };

  return (
    <>
      <html>
        <head>
          <title>DarkLand by GenieDevs</title>
          <link rel="stylesheet" href="./assets/css/main.css" />
          <noscript>
            <link rel="stylesheet" href="./assets/css/noscript.css" />
          </noscript>
        </head>
        <body class="is-preload landing">
          <View style={styles.container}>
            <div id="page-wrapper">
              <HeaderView navigation={navigation} />

              <section id="banner">
                <div class="content">
                  <header>
                    <h2>We build beautiful apps</h2>
                    <p>
                      GenieDevs builds custom, visually stunning apps.
                      <br />
                      Deliver high-quality, beautiful apps that meet your needs.
                    </p>
                  </header>
                  <span class="image">
                    <Image
                      style={{ width: "100%", height: "100%" }}
                      source={require("./images/pic01.jpg")}
                    />
                  </span>
                </div>
                <a href="#one" class="goto-next scrolly">
                  Next
                </a>
              </section>

              <section id="one" class="spotlight style1 bottom">
                <span class="image fit main">
                  <img
                    src="https://baby-monitor-b1e03.web.app/images/pic02.jpg"
                    alt=""
                  />
                </span>
                <div class="content">
                  <div class="container">
                    <div class="row">
                      <div class="col-4 col-12-medium">
                        <header>
                          <h2>What makes working with GenieDevs worthwhile?</h2>
                          <p>
                            Building beautiful apps requires a combination of
                            technical skills, design expertise, and a deep
                            understanding of user experience.
                          </p>
                        </header>
                      </div>
                      <div class="col-4 col-12-medium">
                        <p>
                          GenieDevs is a software company that specializes in
                          building beautiful apps. With a team of expert
                          developers and designers, GenieDevs creates
                          customized, modern apps that are visually appealing,
                          user-friendly, and optimized for various devices and
                          platforms.
                        </p>
                      </div>
                      <div class="col-4 col-12-medium">
                        <p>
                          The company's focus on design and user experience
                          ensures that each app is tailored to meet the unique
                          needs of its clients and their users. Whether it's a
                          mobile app, web app, or desktop app, GenieDevs is
                          dedicated to delivering high-quality, beautiful
                          software solutions that exceed expectations.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <a href="#two" class="goto-next scrolly">
                  Next
                </a>
              </section>

              <section id="two" class="spotlight style2 right">
                <span class="image fit main">
                  <img
                    src="https://baby-monitor-b1e03.web.app/images/pic03.jpg"
                    alt=""
                  />
                </span>
                <div class="content">
                  <header>
                    <h2>Development Services</h2>
                    <p>
                      GenieDevs offers a range of software development services,
                      including but not limited to:
                    </p>
                  </header>
                  <ul>
                    <li>
                      Customized app development for various platforms (mobile,
                      web, desktop)
                    </li>
                    <li>UX/UI design and consulting services </li>
                    <li>Full-stack development and maintenance services </li>
                    <li>Cloud infrastructure and deployment services </li>
                    <li>Quality assurance and testing services </li>
                    <li>Integration with third-party services and APIs </li>
                    <li>
                      Project management and agile development methodologies{" "}
                    </li>
                  </ul>
                  <ul class="actions">
                    <li>
                      <a href="#/services" class="button">
                        Learn More
                      </a>
                    </li>
                  </ul>
                </div>
                <a href="#three" class="goto-next scrolly">
                  Next
                </a>
              </section>

              <section id="three" class="spotlight style3 left">
                <span class="image main bottom">
                  <img
                    src="https://baby-monitor-b1e03.web.app/images/pic04.jpg"
                    alt=""
                  />
                </span>
                <div class="content">
                  <header>
                    <h2>Vision and Mission</h2>
                    <p>
                      At GenieDevs, our vision is to be a leading software
                      development company that provides exceptional solutions
                      globally
                    </p>
                  </header>
                  <p>
                    while our mission is to deliver high-quality services
                    exceeding clients' expectations. We achieve this by
                    collaborating with clients, conducting in-depth analysis,
                    and providing innovative and customized solutions to help
                    clients achieve their business goals. Our team of skilled
                    developers uses the latest technologies and frameworks to
                    create secure and reliable software solutions. We understand
                    the uniqueness of every business, and thus strive to provide
                    customized software solutions that deliver maximum value and
                    post-launch support.
                  </p>
                  <a href="#/about" class="button">
                    Learn More
                  </a>
                </div>
                <a href="#four" class="goto-next scrolly">
                  Next
                </a>
              </section>

              <section id="four" class="wrapper style1 special fade-up">
                <div class="container">
                  <header class="major">
                    <h2>Discover the Cutting-Edge Tech Stack at GenieDevs</h2>
                    <p>
                      We utilize the latest and most advanced technologies to
                      develop high-quality software solutions
                    </p>
                  </header>
                  <div class="box alt">
                    <div class="row gtr-uniform">
                      <section class="col-4 col-6-medium col-12-xsmall">
                        <span class="icon solid  major fa-code"></span>
                        <h3>ReactNative developer</h3>
                        <p>Develop cross-platform apps using JavaScript.</p>
                      </section>
                      <section class="col-4 col-6-medium col-12-xsmall">
                        <span class="icon brands major fa-apple"></span>
                        <h3>iOS native apps</h3>
                        <p>Develop apps specifically for Apple devices.</p>
                      </section>
                      <section class="col-4 col-6-medium col-12-xsmall">
                        <span class="icon brands major fa-android"></span>
                        <h3>Android native apps</h3>
                        <p>Develop apps specifically for Android devices.</p>
                      </section>
                      <section class="col-4 col-6-medium col-12-xsmall">
                        <span class="icon solid  major fa-laptop"></span>
                        <h3>Web Backend development</h3>
                        <p>
                          Develop server-side logic and APIs for web
                          applications.
                        </p>
                      </section>
                      <section class="col-4 col-6-medium col-12-xsmall">
                        <span class="icon solid major fa-magic"></span>
                        <h3>Frontend development</h3>
                        <p>
                          Develop user-facing interfaces for web applications.
                        </p>
                      </section>
                      <section class="col-4 col-6-medium col-12-xsmall">
                        <span class="icon solid major fa-brush"></span>
                        <h3>UI/UX development</h3>
                        <p>
                          Design and develop user interfaces for optimal user
                          experience.
                        </p>
                      </section>
                    </div>
                  </div>
                  <footer class="major">
                    <ul class="actions special">
                      <li>
                        <a href="#/quote" class="button">
                          Get a Quotation
                        </a>
                      </li>
                    </ul>
                  </footer>
                </div>
              </section>

              <section id="five" class="wrapper style2 special fade">
                <div class="container">
                  <header>
                    <h2>Let's Bring Your Vision to Life</h2>
                    <p>
                      Reach out to our expert team to discuss your software
                      needs
                    </p>
                  </header>
                  <form method="" action="subscribe" class="cta">
                    <View></View>

                    <div class="row gtr-uniform gtr-50">
                      <div class="col-8 col-12-xsmall">
                        {/* <TextInput
                          id="email"
                          placeholder="Your Email Address"
                          value={email}
                          onChangeText={handleEmailChange}
                        /> */}
                        <input
                          type="email"
                          name="email"
                          placeholder="Your Email Address"
                        />
                      </div>
                      <div class="col-4 col-12-xsmall">
                        <input
                          type="submit"
                          value="Get Started"
                          class="fit primary"
                        />
                      </div>
                      <div class="col-8 col-12-xsmall"></div>
                      {/* <div class="col-4 col-12-xsmall">
                        <TouchableOpacity onPress={handleSubmit}>
                          <div value="Get Started" class="button primary" />
                        </TouchableOpacity>
                      </div> */}
                    </div>
                  </form>
                </div>
              </section>

              <FooterView navigation={navigation} />
            </div>

            <script src="https://baby-monitor-b1e03.web.app/js/jquery.min.js"></script>
            <script src="https://baby-monitor-b1e03.web.app/js/jquery.scrolly.min.js"></script>
            <script src="https://baby-monitor-b1e03.web.app/js/jquery.dropotron.min.js"></script>
            <script src="https://baby-monitor-b1e03.web.app/js/jquery.scrollex.min.js"></script>
            <script src="https://baby-monitor-b1e03.web.app/js/browser.min.js"></script>
            <script src="https://baby-monitor-b1e03.web.app/js/breakpoints.min.js"></script>
            <script src="https://baby-monitor-b1e03.web.app/js/util.js"></script>
            <script src="https://baby-monitor-b1e03.web.app/js/main.js"></script>
          </View>
        </body>
      </html>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    display: "block",
  },
});
