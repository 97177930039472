import React, { useState } from "react";

import {
  View,
  Text,
  StyleSheet,
  Dimensions,
  TextInput,
  Button,
  Alert,
  TouchableOpacity,
} from "react-native";
import { firebase } from "../configs/firebaseConfig";
import { createStackNavigator } from "react-navigation-stack";
import Constants from "expo-constants";

import HeaderView from "../components/HeaderView";
import FooterView from "../components/FooterView";

import "../assets/css/main.css";
import "../assets/css/noscript.css";

export default function AboutScreen({ navigation }) {
  return (
    <>
      <html>
        <head>
          <title>DarkLand by GenieDevs</title>
          <link rel="stylesheet" href="./assets/css/main.css" />
          <noscript>
            <link rel="stylesheet" href="./assets/css/noscript.css" />
          </noscript>
        </head>
        <body class="is-preload landing">
          <View style={styles.container}>
            <div id="page-wrapper">
              <HeaderView navigation={navigation} />
              <div id="main" class="wrapper style1">
                <div class="container">
                  <header class="major">
                    <h2>About</h2>
                    <p>Discover Our Vision and Mission at GenieDevs</p>
                  </header>
                  <div class="row gtr-150">
                    <div class="col-8 col-12-medium">
                      <section id="content">
                        <a href="#" class="image fit">
                          <img src="images/pic06.jpg" alt="" />
                        </a>
                        <h3>Our Vision and Mission at GenieDevs</h3>
                        <p>
                          At GenieDevs, we have a clear vision and mission that
                          guides our software development endeavors. Our vision
                          is to become a leading software development company
                          that provides exceptional solutions to businesses and
                          individuals worldwide. To achieve this vision, we are
                          committed to delivering high-quality services that
                          exceed the expectations of our clients.
                        </p>
                        <h3>
                          Delivering High-Quality Services to Exceed
                          Expectations
                        </h3>
                        <p>
                          To fulfill our mission, we strive to provide our
                          clients with innovative and cutting-edge technology
                          solutions that help them to achieve their business
                          goals. Our team of skilled and experienced developers
                          is dedicated to using the latest technologies and
                          frameworks to create software solutions that are
                          scalable, reliable, and secure.
                        </p>
                        <h3>
                          Innovative Solutions for Achieving Business Goals
                        </h3>
                        <p>
                          We understand that every business is unique, and
                          that's why we work closely with our clients to
                          understand their needs and requirements. By doing so,
                          we can provide customized software solutions that meet
                          their specific needs and deliver maximum value.
                        </p>
                        <ul>
                          <li>Conducting in-depth analysis.</li>
                          <li>Collaborating with clients.</li>
                          <li>Using flexible development methodologies.</li>
                          <li>Incorporating user feedback.</li>
                          <li>Offering post-launch support.</li>
                        </ul>
                      </section>
                    </div>
                    <div class="col-4 col-12-medium">
                      <section id="sidebar">
                        <section>
                          <h3>
                            Outstanding Customer Service to Build Strong
                            Relationships
                          </h3>
                          <p>
                            In addition to providing excellent software
                            development services, we are also committed to
                            delivering outstanding customer service. We believe
                            in building strong relationships with our clients,
                            and we go the extra mile to ensure their
                            satisfaction with our services.
                          </p>
                        </section>
                        <hr />
                        <section>
                          <a href="#" class="image fit">
                            <img src="images/pic07.jpg" alt="" />
                          </a>
                          <h3>
                            Maintaining High Standards for Quality and
                            Satisfaction
                          </h3>
                          <p>
                            Overall, our vision and mission at GenieDevs are
                            geared towards providing exceptional software
                            development services to our clients, while
                            maintaining the highest standards of quality and
                            customer satisfaction. We believe that by staying
                            true to these values, we can continue to grow and
                            expand our services to reach a wider audience.
                          </p>
                        </section>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
              <FooterView navigation={navigation} />
            </div>

            <script src="https://baby-monitor-b1e03.web.app/js/jquery.min.js"></script>
            <script src="https://baby-monitor-b1e03.web.app/js/jquery.scrolly.min.js"></script>
            <script src="https://baby-monitor-b1e03.web.app/js/jquery.dropotron.min.js"></script>
            <script src="https://baby-monitor-b1e03.web.app/js/jquery.scrollex.min.js"></script>
            <script src="https://baby-monitor-b1e03.web.app/js/browser.min.js"></script>
            <script src="https://baby-monitor-b1e03.web.app/js/breakpoints.min.js"></script>
            <script src="https://baby-monitor-b1e03.web.app/js/util.js"></script>
            <script src="https://baby-monitor-b1e03.web.app/js/main.js"></script>
          </View>
        </body>
      </html>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    display: "block",
  },
});
