import React, { useState } from "react";

import {
  View,
  Text,
  StyleSheet,
  Dimensions,
  TextInput,
  Button,
  Alert,
  TouchableOpacity,
} from "react-native";
import { firebase } from "../configs/firebaseConfig";
import { createStackNavigator } from "react-navigation-stack";
import HeaderView from "../components/HeaderView";
import FooterView from "../components/FooterView";
import Constants from "expo-constants";

import "../assets/css/main.css";
import "../assets/css/noscript.css";

export default function ServicesScreen({ navigation }) {
  return (
    <>
      <html>
        <head>
          <title>DarkLand by GenieDevs</title>
          <link rel="stylesheet" href="./assets/css/main.css" />
          <noscript>
            <link rel="stylesheet" href="./assets/css/noscript.css" />
          </noscript>
        </head>
        <body class="is-preload landing">
          <View style={styles.container}>
            <div id="page-wrapper">
              <HeaderView navigation={navigation} />
              <div id="main" class="wrapper style1">
                <div class="container">
                  <header class="major">
                    <h2>Services</h2>
                    <p>
                      You have come to the right place as we offer a variety of
                      services that cater to your needs.
                    </p>
                  </header>
                  <div class="row gtr-150">
                    <div class="col-6 col-12-medium imp-medium">
                      <section id="sidebar">
                        <section>
                          <h3>Core Services We Offer at GenieDevs</h3>
                          <p>
                            Mobile App Development is one of the core services
                            provided by GenieDevs. The company has expertise in
                            both Native and React Native development for mobile
                            applications. Native app development is the process
                            of creating apps for specific platforms like iOS or
                            Android using their respective programming
                            languages, while React Native development allows
                            developers to build cross-platform mobile apps using
                            a single codebase.
                          </p>
                          <p>
                            At GenieDevs, the mobile app development team
                            consists of highly skilled and experienced
                            developers who have the expertise to develop
                            high-quality mobile apps for businesses of all
                            sizes. The company follows a comprehensive mobile
                            app development process that includes requirements
                            gathering, designing, development, testing, and
                            deployment.
                          </p>
                          <p>
                            {" "}
                            For native app development, GenieDevs uses
                            programming languages such as Swift for iOS apps and
                            Kotlin or Java for Android apps. The company also
                            uses various tools and frameworks like Xcode,
                            Android Studio, and Firebase to create
                            high-performance, secure, and reliable mobile apps.
                          </p>
                          <p>
                            For React Native development, the company uses
                            JavaScript along with the React Native framework to
                            create cross-platform apps that work seamlessly on
                            both iOS and Android platforms. The use of React
                            Native enables faster development, better app
                            performance, and reduced development costs.
                          </p>
                          <p>
                            GenieDevs understands that every business has unique
                            requirements, and that's why the company offers
                            customized mobile app development solutions that are
                            tailored to the specific needs of each client. The
                            team works closely with clients to understand their
                            business objectives, target audience, and user
                            requirements to create mobile apps that deliver
                            maximum value.
                          </p>
                          <p>
                            Overall, GenieDevs is committed to delivering
                            high-quality mobile app development services using
                            both Native and React Native technologies to help
                            businesses achieve their goals and reach their
                            target audience.
                          </p>
                          <footer>
                            <ul class="actions">
                              <li>
                                <a href="#quote" class="button">
                                  Get Quote
                                </a>
                              </li>
                            </ul>
                          </footer>
                        </section>
                        <hr />
                      </section>
                    </div>
                    <div class="col-6 col-12-medium">
                      <section id="content">
                        <a href="#" class="image fit">
                          <img src="images/pic05.jpg" alt="" />
                        </a>
                        <h3> Mobile App Development</h3>
                        <p>
                          This service involves the creation of mobile
                          applications for various platforms such as iOS and
                          Android. The development process includes design,
                          development, and testing to ensure that the app is
                          user-friendly and meets the client's requirements. The
                          app can be customized to suit the specific needs of
                          the client's business.
                        </p>
                        <h3>Web Development</h3>
                        <p>
                          This service involves the creation of websites, web
                          applications, and e-commerce sites. The development
                          process includes designing, developing, and testing
                          websites using various web technologies such as HTML,
                          CSS, and JavaScript. The website can be customized to
                          suit the specific needs of the client's business.
                        </p>
                        <h3>Software Development</h3>
                        <p>
                          This service involves the creation of software
                          solutions for businesses and individuals. The
                          development process includes designing, developing,
                          and testing software solutions using various
                          programming languages such as Java, Python, and C#.
                          The software can be customized to suit the specific
                          needs of the client's business.
                        </p>
                        <h3>UI/UX Design</h3>
                        <p>
                          This service involves the creation of user interfaces
                          and user experiences for websites, mobile apps, and
                          software solutions. The design process includes
                          creating wireframes, mockups, and prototypes to ensure
                          that the design is user-friendly and meets the
                          client's requirements. The design can be customized to
                          suit the specific needs of the client's business.
                        </p>
                        <h3>Quality Assurance and Testing</h3>
                        <p>
                          This service involves the testing and quality
                          assurance of software solutions to ensure that they
                          meet the client's requirements and are free of bugs
                          and errors. The testing process includes functional
                          testing, regression testing, and user acceptance
                          testing. The quality assurance and testing can be
                          customized to suit the specific needs of the client's
                          business.
                        </p>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
              <FooterView navigation={navigation} />
            </div>

            <script src="https://baby-monitor-b1e03.web.app/js/jquery.min.js"></script>
            <script src="https://baby-monitor-b1e03.web.app/js/jquery.scrolly.min.js"></script>
            <script src="https://baby-monitor-b1e03.web.app/js/jquery.dropotron.min.js"></script>
            <script src="https://baby-monitor-b1e03.web.app/js/jquery.scrollex.min.js"></script>
            <script src="https://baby-monitor-b1e03.web.app/js/browser.min.js"></script>
            <script src="https://baby-monitor-b1e03.web.app/js/breakpoints.min.js"></script>
            <script src="https://baby-monitor-b1e03.web.app/js/util.js"></script>
            <script src="https://baby-monitor-b1e03.web.app/js/main.js"></script>
          </View>
        </body>
      </html>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    display: "block",
  },
});
