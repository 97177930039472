import React, { useState, useEffect } from "react";
import { Alert, TouchableOpacity } from "react-native";

const FooterView = ({ navigation }) => {
  return (
    <footer id="footer">
      <ul class="icons">
        <li>
          <a
            href="http://twitter.com/geniedevs"
            class="icon brands alt fa-twitter"
          >
            <span class="label">Twitter</span>
          </a>
        </li>
        <li>
          <a
            href="http://fb.com/geniedevs"
            class="icon brands alt fa-facebook-f"
          >
            <span class="label">Facebook</span>
          </a>
        </li>
        <li>
          <a
            href="https://www.linkedin.com/company/geniedevs"
            class="icon brands alt fa-linkedin-in"
          >
            <span class="label">LinkedIn</span>
          </a>
        </li>
        <li>
          <a
            href="https://www.intragram.com/geniedevs"
            class="icon brands alt fa-instagram"
          >
            <span class="label">Instagram</span>
          </a>
        </li>
        <li>
          <a
            href="https://github.com/git-geniedevs"
            class="icon brands alt fa-github"
          >
            <span class="label">GitHub</span>
          </a>
        </li>
        <li>
          <a href="admin@geniedevs.com" class="icon solid alt fa-envelope">
            <span class="label">Email</span>
          </a>
        </li>
      </ul>
      <ul class="copyright">
        <li>&copy; GenieDevs. All rights reserved.</li>
        <li>Crafted with creativity and innovation.</li>
        <li>
          <a href="http://geniedevs.com">Powered by: Team GenieDevs</a>
        </li>
      </ul>
    </footer>
  );
};

export default FooterView;
