import React, { useState } from "react";

import {
  View,
  Text,
  StyleSheet,
  Dimensions,
  TextInput,
  Button,
  Alert,
  TouchableOpacity,
} from "react-native";
import { firebase } from "../configs/firebaseConfig";
import { createStackNavigator } from "react-navigation-stack";
import Constants from "expo-constants";

import HeaderView from "../components/HeaderView";
import FooterView from "../components/FooterView";

import "../assets/css/main.css";
import "../assets/css/noscript.css";

export default function CareerScreen({ navigation }) {
  return (
    <>
      <html>
        <head>
          <title>DarkLand by GenieDevs</title>
          <link rel="stylesheet" href="./assets/css/main.css" />
          <noscript>
            <link rel="stylesheet" href="./assets/css/noscript.css" />
          </noscript>
        </head>
        <body class="is-preload landing">
          <View style={styles.container}>
            <div id="page-wrapper">
              <HeaderView navigation={navigation} />
              <div id="main" class="wrapper style1">
                <div class="container">
                  <header class="major">
                    <h2>Career</h2>
                    <p>
                      Find opportunities at GenieDevs for skilled professionals.
                    </p>
                  </header>
                  <div class="row gtr-150">
                    <div class="col-12 col-12-medium">
                      <section id="content">
                        <a href="#" class="image fit">
                          <img src="images/pic06.jpg" alt="" />
                        </a>
                        <h3>Join Our Team at GenieDevs</h3>
                        <p>
                          At GenieDevs, we are always on the lookout for
                          talented tech professionals who are passionate about
                          software development and want to make a difference in
                          the industry. As a growing software development
                          company, we offer a range of exciting career
                          opportunities for individuals with a diverse range of
                          skills and expertise.
                        </p>
                        <p>
                          As a member of the GenieDevs team, you will have the
                          opportunity to work on a wide range of projects for
                          clients across various industries, providing you with
                          the chance to learn new skills and gain valuable
                          experience. We believe in fostering a culture of
                          collaboration and innovation, where our team members
                          can work together to develop cutting-edge software
                          solutions that exceed the expectations of our clients.
                        </p>
                        <p>
                          If you have the required skills and experience, we
                          invite you to send your resumes to{" "}
                          <a href="mailto:hr@geniedevs.com">hr@geniedevs.com</a>
                          . We offer competitive compensation packages, flexible
                          work arrangements, and opportunities for career growth
                          and development. Join us in our mission to deliver
                          innovative and cutting-edge technology solutions to
                          businesses and individuals worldwide.
                        </p>
                        <h3>
                          We are currently looking for the following candidates:
                        </h3>
                        <ul>
                          <li>5 x React Native developers </li>
                          <li>2 x iOS Native developers</li>
                          <li>3 x Android Native developers</li>
                          <li>2 x PHP Laravel developers</li>
                          <li>1 x UI/UX developer</li>
                        </ul>

                        <footer>
                          <ul class="actions">
                            <li>
                              <a href="mailto:hr@geniedevs.com" class="button">
                                Apply Now
                              </a>
                            </li>
                          </ul>
                        </footer>

                        <blockquote>
                          "The future belongs to those who believe in the beauty
                          of their dreams." - Eleanor Roosevelt
                        </blockquote>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
              <FooterView navigation={navigation} />
            </div>

            <script src="https://baby-monitor-b1e03.web.app/js/jquery.min.js"></script>
            <script src="https://baby-monitor-b1e03.web.app/js/jquery.scrolly.min.js"></script>
            <script src="https://baby-monitor-b1e03.web.app/js/jquery.dropotron.min.js"></script>
            <script src="https://baby-monitor-b1e03.web.app/js/jquery.scrollex.min.js"></script>
            <script src="https://baby-monitor-b1e03.web.app/js/browser.min.js"></script>
            <script src="https://baby-monitor-b1e03.web.app/js/breakpoints.min.js"></script>
            <script src="https://baby-monitor-b1e03.web.app/js/util.js"></script>
            <script src="https://baby-monitor-b1e03.web.app/js/main.js"></script>
          </View>
        </body>
      </html>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    display: "block",
  },
});
