import React from "react";
import { View, Text, TouchableOpacity, Linking } from "react-native";
import { createAppContainer } from "react-navigation";
import { createStackNavigator } from "react-navigation-stack";
import { HashRouter, Route, Switch } from "react-router-dom";

import AboutScreen from "./app/screens/AboutScreen";
import CareerScreen from "./app/screens/CareerScreen";
import ElementScreen from "./app/screens/ElementScreen";
import ContactScreen from "./app/screens/ContactScreen";
import DetailScreen from "./app/screens/DetailScreen";
import EmptyScreen from "./app/screens/EmptyScreen";
import MainScreen from "./app/screens/MainScreen";
import ProductsScreen from "./app/screens/ProductsScreen";
import QuoteScreen from "./app/screens/QuoteScreen";
import ServicesScreen from "./app/screens/ServicesScreen";

const StackNavigator = createStackNavigator(
  {
    about: {
      screen: AboutScreen,
      navigationOptions: {
        headerShown: false,
      },
    },
    career: {
      screen: CareerScreen,
      navigationOptions: {
        headerShown: false,
      },
    },
    contact: {
      screen: ContactScreen,
      navigationOptions: {
        headerShown: false,
      },
    },
    detail: {
      screen: DetailScreen,
      navigationOptions: {
        headerShown: false,
      },
    },
    home: {
      screen: MainScreen,
      navigationOptions: {
        headerShown: false,
      },
    },
    main: {
      screen: MainScreen,
      navigationOptions: {
        headerShown: false,
      },
    },
    products: {
      screen: ProductsScreen,
      navigationOptions: {
        headerShown: false,
      },
    },
    quote: {
      screen: QuoteScreen,
      navigationOptions: {
        headerShown: false,
      },
    },
    services: {
      screen: ServicesScreen,
      navigationOptions: {
        headerShown: false,
      },
    },
  },
  {
    initialRouteName: "home",
  }
);

const AppContainer = createAppContainer(StackNavigator);

export default function App() {
  // return <AppContainer style={{}} />;
  return (
    <HashRouter>
      <Switch>
        <Route exact path="/" component={MainScreen} />
        <Route path="/one" component={MainScreen} />
        <Route path="/two" component={MainScreen} />
        <Route path="/three" component={MainScreen} />
        <Route path="/four" component={MainScreen} />
        <Route path="/empty" component={EmptyScreen} />
        <Route path="/about" component={AboutScreen} />
        <Route path="/contact" component={ContactScreen} />
        <Route path="/element" component={ElementScreen} />
        <Route path="/quote" component={QuoteScreen} />
        <Route path="/home" component={MainScreen} />
        <Route path="/career" component={CareerScreen} />
        <Route path="/services" component={ServicesScreen} />
        <Route path="/products" component={ProductsScreen} />
        <Route path="/detail" component={DetailScreen} />
      </Switch>
    </HashRouter>
  );
}
