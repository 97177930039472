import React, { useState } from "react";

import {
  View,
  Text,
  StyleSheet,
  Dimensions,
  TextInput,
  Button,
  Alert,
  TouchableOpacity,
} from "react-native";
import { firebase } from "../configs/firebaseConfig";
import { createStackNavigator } from "react-navigation-stack";
import Constants from "expo-constants";

import HeaderView from "../components/HeaderView";
import FooterView from "../components/FooterView";

import "../assets/css/main.css";
import "../assets/css/noscript.css";

export default function ProductsScreen({ navigation }) {
  return (
    <>
      <html>
        <head>
          <title>DarkLand by GenieDevs</title>
          <link rel="stylesheet" href="./assets/css/main.css" />
          <noscript>
            <link rel="stylesheet" href="./assets/css/noscript.css" />
          </noscript>
        </head>
        <body class="is-preload landing">
          <View style={styles.container}>
            <div id="page-wrapper">
              <HeaderView navigation={navigation} />
              <div id="main" class="wrapper style1">
                <div class="container">
                  <header class="major">
                    <h2>Products</h2>
                    <p>
                      Ipsum dolor feugiat aliquam tempus sed magna lorem
                      consequat accumsan
                    </p>
                  </header>
                  <div class="row gtr-150">
                    <div class="col-12 col-12-medium">
                      <section>
                        <h4>Left &amp; Right</h4>
                        <p>
                          <span class="image left">
                            <img src="images/pic08.jpg" alt="" />
                          </span>
                          Fringilla nisl. Donec accumsan interdum nisi, quis
                          tincidunt felis sagittis eget. tempus euismod.
                          Vestibulum ante ipsum primis in faucibus vestibulum.
                          Blandit adipiscing eu felis iaculis volutpat ac
                          adipiscing accumsan eu faucibus. Integer ac
                          pellentesque praesent tincidunt felis sagittis eget.
                          tempus euismod. Vestibulum ante ipsum primis in
                          faucibus vestibulum. Blandit adipiscing eu felis
                          iaculis volutpat ac adipiscing accumsan eu faucibus.
                          Integer ac pellentesque praesent. Donec accumsan
                          interdum nisi, quis tincidunt felis sagittis eget.
                          tempus euismod. Vestibulum ante ipsum primis in
                          faucibus vestibulum. Blandit adipiscing eu felis
                          iaculis volutpat ac adipiscing accumsan eu faucibus.
                          Integer ac pellentesque praesent tincidunt felis
                          sagittis eget. tempus euismod. Vestibulum ante ipsum
                          primis in faucibus vestibulum. Blandit adipiscing eu
                          felis iaculis volutpat ac adipiscing accumsan eu
                          faucibus. Integer ac pellentesque praesent. Cras
                          aliquet accumsan curae accumsan arcu amet egestas
                          placerat odio morbi mi adipiscing col morbi felis
                          faucibus in gravida sollicitudin interdum commodo.
                          Ante aliquam vis iaculis accumsan lorem ipsum dolor
                          sit amet nullam. Cras aliquet accumsan curae accumsan
                          arcu amet egestas placerat odio morbi mi adipiscing
                          col morbi felis faucibus in gravida sollicitudin
                          interdum commodo. Ante aliquam vis iaculis accumsan
                          lorem ipsum dolor sit amet nullam.
                        </p>
                        <p>
                          <span class="image right">
                            <img src="images/pic08.jpg" alt="" />
                          </span>
                          Fringilla nisl. Donec accumsan interdum nisi, quis
                          tincidunt felis sagittis eget. tempus euismod.
                          Vestibulum ante ipsum primis in faucibus vestibulum.
                          Blandit adipiscing eu felis iaculis volutpat ac
                          adipiscing accumsan eu faucibus. Integer ac
                          pellentesque praesent tincidunt felis sagittis eget.
                          tempus euismod. Vestibulum ante ipsum primis in
                          faucibus vestibulum. Blandit adipiscing eu felis
                          iaculis volutpat ac adipiscing accumsan eu faucibus.
                          Integer ac pellentesque praesent. Donec accumsan
                          interdum nisi, quis tincidunt felis sagittis eget.
                          tempus euismod. Vestibulum ante ipsum primis in
                          faucibus vestibulum. Blandit adipiscing eu felis
                          iaculis volutpat ac adipiscing accumsan eu faucibus.
                          Integer ac pellentesque praesent tincidunt felis
                          sagittis eget. tempus euismod. Vestibulum ante ipsum
                          primis in faucibus vestibulum. Blandit adipiscing eu
                          felis iaculis volutpat ac adipiscing accumsan eu
                          faucibus. Integer ac pellentesque praesent. Cras
                          aliquet accumsan curae accumsan arcu amet egestas
                          placerat odio morbi mi adipiscing col morbi felis
                          faucibus in gravida sollicitudin interdum commodo.
                          Ante aliquam vis iaculis accumsan lorem ipsum dolor
                          sit amet nullam. Cras aliquet accumsan curae accumsan
                          arcu amet egestas placerat odio morbi mi adipiscing
                          col morbi felis faucibus in gravida sollicitudin
                          interdum commodo. Ante aliquam vis iaculis accumsan
                          lorem ipsum dolor sit amet nullam.
                        </p>

                        <h4>Blockquote</h4>
                        <blockquote>
                          Fringilla nisl. Donec accumsan interdum nisi, quis
                          tincidunt felis sagittis eget tempus euismod.
                          Vestibulum ante ipsum primis in faucibus vestibulum.
                          Blandit adipiscing eu felis iaculis volutpat ac
                          adipiscing accumsan faucibus. Vestibulum ante ipsum
                          primis in faucibus lorem ipsum dolor sit amet nullam
                          adipiscing eu felis.
                        </blockquote>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
              <FooterView navigation={navigation} />
            </div>

            <script src="https://baby-monitor-b1e03.web.app/js/jquery.min.js"></script>
            <script src="https://baby-monitor-b1e03.web.app/js/jquery.scrolly.min.js"></script>
            <script src="https://baby-monitor-b1e03.web.app/js/jquery.dropotron.min.js"></script>
            <script src="https://baby-monitor-b1e03.web.app/js/jquery.scrollex.min.js"></script>
            <script src="https://baby-monitor-b1e03.web.app/js/browser.min.js"></script>
            <script src="https://baby-monitor-b1e03.web.app/js/breakpoints.min.js"></script>
            <script src="https://baby-monitor-b1e03.web.app/js/util.js"></script>
            <script src="https://baby-monitor-b1e03.web.app/js/main.js"></script>
          </View>
        </body>
      </html>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    display: "block",
  },
});
