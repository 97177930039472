import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCGSDJgIYW-oqtgMRyuaPh7VRBh0aEJdoI",
  authDomain: "baby-monitor-b1e03.firebaseapp.com",
  projectId: "baby-monitor-b1e03",
  storageBucket: "baby-monitor-b1e03.appspot.com",
  messagingSenderId: "310614368622",
  appId: "1:310614368622:web:db32cd4bbf8309e892a17f",
  measurementId: "G-8KRKMDF1G5",
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

export { firebase };
