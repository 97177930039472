import React, { useState } from "react";

import {
  View,
  Text,
  StyleSheet,
  Dimensions,
  TextInput,
  Button,
  Alert,
  TouchableOpacity,
} from "react-native";
import { firebase } from "../configs/firebaseConfig";
import { createStackNavigator } from "react-navigation-stack";
import Constants from "expo-constants";

import HeaderView from "../components/HeaderView";
import FooterView from "../components/FooterView";

import "../assets/css/main.css";
import "../assets/css/noscript.css";

export default function QuoteScreen({ navigation }) {
  return (
    <>
      <html>
        <head>
          <title>DarkLand by GenieDevs</title>
          <link rel="stylesheet" href="./assets/css/main.css" />
          <noscript>
            <link rel="stylesheet" href="./assets/css/noscript.css" />
          </noscript>
        </head>
        <body class="is-preload landing">
          <View style={styles.container}>
            <div id="page-wrapper">
              <HeaderView navigation={navigation} />
              <div id="main" class="wrapper style1">
                <div class="container">
                  <header class="major">
                    <h2>Quote</h2>
                    <p>
                      Receive a project estimate from our team of technical
                      experts at Geniedevs.
                    </p>
                  </header>
                  <div class="row gtr-150">
                    <div class="col-12 col-12-medium">
                      <section id="content">
                        <div class="container">
                          <form
                            method="post"
                            action="#"
                            enctype="multipart/form-data"
                          >
                            <div class="row gtr-uniform gtr-50">
                              <div class="col-6 col-12-xsmall">
                                <input
                                  type="text"
                                  name="name"
                                  id="name"
                                  value=""
                                  placeholder="Name"
                                />
                              </div>
                              <div class="col-6 col-12-xsmall">
                                <input
                                  type="email"
                                  name="email"
                                  id="email"
                                  value=""
                                  placeholder="Email"
                                />
                              </div>
                              <div class="col-6 col-12-xsmall">
                                <input
                                  type="text"
                                  name="phone"
                                  id="phone"
                                  value=""
                                  placeholder="Phone"
                                />
                              </div>
                              <div class="col-6 col-12-xsmall">
                                <input
                                  type="text"
                                  name="company"
                                  id="company"
                                  value=""
                                  placeholder="Company Name"
                                />
                              </div>
                              <div class="col-12">
                                <select name="category" id="category">
                                  <option value="">Category</option>
                                  <option value="1">
                                    Mobile Application Development
                                  </option>
                                  <option value="2">
                                    Web Application Development
                                  </option>
                                  <option value="2">
                                    Custom Software Development
                                  </option>
                                  <option value="4">
                                    Cloud Application Development
                                  </option>
                                  <option value="5">
                                    Artificial Intelligence and Machine Learning
                                    Solutions
                                  </option>
                                  <option value="6">
                                    Internet of Things (IoT) Development
                                  </option>
                                  <option value="7">
                                    Blockchain Development
                                  </option>
                                  <option value="71">DevOps Services</option>
                                  <option value="72">
                                    UX/UI Design and Development
                                  </option>
                                  <option value="8">
                                    Quality Assurance and Testing
                                  </option>
                                  <option value="9">
                                    Legacy System Migration and Modernization
                                  </option>
                                  <option value="91">
                                    Software Maintenance and Support
                                  </option>
                                  <option value="10">Big Data Analytics</option>
                                  <option value="11">
                                    IT Consulting and Strategy
                                  </option>
                                  <option value="12">Other</option>
                                </select>
                              </div>

                              <div class="col-12">
                                <select name="budget" id="budget">
                                  <option value="">Budget</option>
                                  <option value="1">Less than $5,000</option>
                                  <option value="2">$5,000 - $10,000</option>
                                  <option value="3">$25,000 - $50,000</option>
                                  <option value="4">$50,000 - $100,000</option>
                                  <option value="5">More than $100,000</option>
                                </select>
                              </div>

                              <div class="col-12">
                                <textarea
                                  name="project_detail"
                                  id="message"
                                  placeholder="Brief description of the project"
                                  rows="6"
                                ></textarea>
                              </div>

                              <div class="col-12">
                                <select name="timeline" id="timeline">
                                  <option value="">Timeline</option>
                                  <option value="1">1-3 months</option>
                                  <option value="2">3-6 months</option>
                                  <option value="3">6-9 months</option>
                                  <option value="4">9-12 months</option>
                                  <option value="5">12+ months</option>
                                  <option value="6">Not sure</option>
                                </select>
                              </div>

                              <div class="col-12">
                                <p>
                                  Attachments documents (if required):-
                                  <input type="file" id="file" name="file" />
                                </p>
                              </div>

                              {/* <div class="col-6 col-12-medium">
                        <input type="checkbox" id="copy" name="copy" />
                        <label for="copy">
                          Email me a copy of this message
                        </label></div> */}
                              <div class="col-12">
                                <ul class="actions">
                                  <li>
                                    <input
                                      type="submit"
                                      value="Send Message"
                                      class="primary"
                                    />
                                  </li>
                                  <li>
                                    <input type="reset" value="Reset" />
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </form>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
              <FooterView navigation={navigation} />
            </div>

            <script src="https://baby-monitor-b1e03.web.app/js/jquery.min.js"></script>
            <script src="https://baby-monitor-b1e03.web.app/js/jquery.scrolly.min.js"></script>
            <script src="https://baby-monitor-b1e03.web.app/js/jquery.dropotron.min.js"></script>
            <script src="https://baby-monitor-b1e03.web.app/js/jquery.scrollex.min.js"></script>
            <script src="https://baby-monitor-b1e03.web.app/js/browser.min.js"></script>
            <script src="https://baby-monitor-b1e03.web.app/js/breakpoints.min.js"></script>
            <script src="https://baby-monitor-b1e03.web.app/js/util.js"></script>
            <script src="https://baby-monitor-b1e03.web.app/js/main.js"></script>
          </View>
        </body>
      </html>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    display: "block",
  },
});
